//sass for custom radio inputs and checkbox

.radios-container
  padding: 5px 0

.radio-wrap
  display: block
  float: left
  position: relative

  label
    color: $gray
    cursor: pointer
    display: block
    font-size: 16px
    font-weight: 300
    margin-bottom: 0
    padding: 0 25px 0 30px
    position: relative
    transition: all 0.25s linear

  input[type=radio]
    cursor: pointer
    height: 20px
    opacity: 0
    position: absolute
    width: 20px
    z-index: 15

  .selected
    border: 2px solid grey
    border-radius: 100%
    cursor: pointer
    display: block
    height: 20px
    position: absolute
    top: 0
    transition: border 0.25s linear
    transition: border 0.25s linear
    width: 20px

  .selected::before
    border-radius: 100%
    content: ''
    cursor: pointer
    display: block
    height: 10px
    left: 3px
    margin: auto
    position: absolute
    top: 3px
    transition: background 0.25s linear
    transition: background 0.25s linear
    width: 10px

input[type=radio]:checked ~ .selected
  border: 2px solid blue

input[type=radio]:checked ~ .selected::before
  background: $orange

input[type=radio]:checked ~ label
  color: $orange

.label--checkbox
  cursor: pointer
  line-height: 135%
  margin: 0.5rem
  position: relative

.checkbox-custom
  cursor: pointer
  margin: 0 10px 0 0 !important
  margin-right: 10px
  position: relative

  &:focus
    outline: none

  &::before
    border: 2px solid grey
    content: ''
    height: 16px
    left: 0
    position: absolute
    top: 0
    transition: all 0.3s ease-in-out
    transition: all 0.3s ease-in-out
    width: 16px
    z-index: 1

  &:checked
    &::before
      border-color: $orange
      border-right-style: none
      border-top-style: none
      height: 8px
      transform: rotate(-45deg)
      transform: rotate(-45deg)
      transform: rotate(-45deg)

  &::after
    background: white
    content: ''
    cursor: pointer
    height: 18px
    left: 0
    position: absolute
    top: -2px
    width: 18px
