.rc-switch
  background-color: $gray
  border: none
  border-radius: 20px
  box-sizing: border-box
  cursor: pointer
  display: inline-block
  height: 22px
  line-height: 20px
  position: relative
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1)
  vertical-align: middle
  width: 44px

.rc-switch-inner
  color: white
  font-size: 12px
  left: 24px
  position: absolute

.rc-switch
  &::after
    animation-duration: 0.3s
    animation-name: rcSwitchOff
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1)
    background-color: white
    border-radius: 50%
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26)
    content: ' '
    cursor: pointer
    height: 18px
    left: 2px
    position: absolute
    top: 2px
    transform: scale(1)
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1)
    width: 18px

  &:hover::after
    animation-name: rcSwitchOn
    transform: scale(1.1)

  &:focus
    box-shadow: 0 0 0 2px #D5F1FD
    outline: none

.rc-switch-checked
  background-color: $orange

  .rc-switch-inner
    left: 6px

  &::after
    left: 22px

.rc-switch-disabled
  cursor: no-drop
  opacity: 0.5
  // background: #ccc
  // border-color: #ccc
  &::after
    animation-name: none
    background: #9E9E9E
    cursor: no-drop

  &:hover::after
    animation-name: none
    transform: scale(1)

.rc-switch-label
  display: inline-block
  font-size: 14px
  line-height: 20px
  padding-left: 10px
  pointer-events: none
  user-select: text
  vertical-align: middle
  white-space: normal
