.container-shops
    padding-left: 30px
    padding-right: 30px

    .shopsTable__inline-search
        .nb-shops
            text-transform: uppercase
            font-weight: 400
            font-size: 16px

            .shop
                padding-top: 30px
                float: right
                text-align: right
                padding-right: 0
                color: $gray

            .nb
                color: $orange
                font-size: 20px
                margin-right: 5px

        .mui-textfield
            padding-left: 0

            #autocomplete
                background-image: asset("img/dashboard/search.svg")
                background-position: 10px center
                background-repeat: no-repeat
                background-size: 20px
                padding-left: 40px

    input[type=search]::-webkit-input-placeholder
        color: $gray

.status__pellet
    position: absolute
    top: 50%
    left: 35%
    transform: translate(-50%, -50%)
    width: 12px
    height: 12px
    border-radius: 100%
    display: block
    border: 1px solid $gray
    background-color: transparent

    &.green
        background-color: $green
        border: 1px solid $green

    &.orange
        background-color: $peach
        border: 1px solid $peach

    &.red
        background-color: $negative
        border: 1px solid $negative

.tool-overlay
    background: white
    width: 250px
    padding: 10px
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12)

    &.docs
        width: 125px
        height: 88px
        box-shadow: none !important

.tool-overlay__item
    display: block
    font-size: 11px
    color: $gray
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

    &.merchants
        font-size: 13px
        font-weight: bold
        letter-spacing: 0.65px
        color: white
        text-transform: uppercase
        text-align: center

    &.docs
        font-size: 13px

        &.red
            color: $negative

        &.orange
            color: $peach

ul.pagination
    list-style: none
    display: flex
    flex-direction: row
    justify-content: center
    margin-top: 30px

    li > a
        display: inline-block
        padding: 12px 16px
        color: $gray
        background: none
        cursor: pointer
        margin-left: 10px

        &:focus
            outline: none

        &:hover
            color: $orange

    li:first-child > a
        margin-left: 0
        margin-top: -5px

    li:last-child > a
        margin-top: -5px

    .active > a
        border: 1px solid $orange
        color: $orange
        border-radius: 100%

    a:hover, a:focus
        text-decoration: none

    .lh-arrow_left, .lh-arrow_right
        font-size: 35px
        color: $gray

        &:hover
            color: $orange

    ul.pagination.total-0,
    ul.pagination.total-1
        display: none


.tooltip-localisations
    .ant-tooltip-inner
        background-color: white
