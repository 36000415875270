#dashboard-header
  background: white
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12)
  height: 100px
  overflow: hidden
  padding-left: 30px
  padding-right: 30px
  transition: height 0.3s ease-in-out

  .filters__wrapper
    margin-top: 20px

  &.open
    height: auto
    overflow: visible
    padding-bottom: 20px
    transition: height 0.3s ease-in-out
  // overflow: visible
  // > .filters__wrapper
  //     opacity: 1
  //     transition: opacity .3s ease-in-out

  .header__title
    h2
      font-weight: 300
      margin-bottom: 0
      margin-top: 30px

  .header__buttons
    &.flex-wrapper
      display: flex
      flex-direction: row
      justify-content: flex-end
      margin-bottom: 25px

    .flex-item
      cursor: pointer
      margin-left: 10px
      margin-top: 21px

    .header__buttons-add
      background-color: transparent
      border-radius: 50%
      cursor: pointer
      float: right
      height: 50px
      margin-top: 20px
      width: 50px

      &:hover
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), -3px 3px 2px rgba(0, 0, 0, 0.1)

    .header__buttons-filter-toggler
      cursor: pointer

      > img
        margin-top: 12px


      .lh
        color: $gray
        cursor: pointer
        font-size: 35px
        margin-top: 15px

        &:hover
          color: $orange

        &.lh-updownload
          font-size: 45px
