.ReactTable
  background: white
  border-color: $borderLight !important
  border-top: 0 !important
  color: $gray
  font-size: 12px
  font-weight: 400
  min-height: 200px
  padding-bottom: 0
  z-index: 0

  .rt-thead.-header
    border-bottom: 2px solid lightGrey
    box-shadow: none !important
    color: $gray
    font-size: 12px
    font-weight: 200
    height: 70px
    letter-spacing: 1.5px
    text-transform: uppercase

    .rt-tr
      text-align: left

    .rt-th
      border-right: none
      color: $gray
      font-size: 13px
      font-weight: bold
      letter-spacing: 0.65px
      position: relative

      &.rthfc-th-fixed
        position: sticky

      &.rthfc-th-fixed-left-last
        //border-right: solid 1px #ccc

      &:focus
        outline: none

      > div
        position: absolute
        top: 50%
        transform: translateY(-50%)

      &:first-of-type
        padding-left: 5px

  .rt-tr-group
    border-bottom: 2px solid lightGrey
    min-height: 60px

  .rt-tbody .rt-td
    border: none
    line-height: 40px
    position: relative

  .lh-button.is-outlined.table-button
    border-radius: 5px
    font-size: 11px
    text-decoration: none

  .table-item
    font-size: 13px
    padding: 6px
    position: absolute
    top: 50%
    transform: translateY(-50%)

    .table-item__action
      cursor: pointer
      margin-right: 5px

    &.buttons
      > .table-button
        float: left
        min-width: 81px
        text-align: center

      > .table-button:last-child
        margin-top: 5px

  .pagination-bottom
    background: $borderLight

    .-pagination .-btn
      background: rgba(26, 120, 168, 0.75)
      color: white

      &:hover
        background: $orange !important

  .rt-table
    padding: 0 20px

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover
  background: rgba(0, 0, 0, 0.03) !important
