@font-face {
  font-family: "Bruno JB Std";
  src: url("../fonts/BrunoJBStd.otf");
}

@font-face {
  font-family: "NT Wagner";
  src: url("../fonts/NT Wagner.otf");
}

@font-face {
  font-family: "Plus Jakarta Display";
  src: url("../fonts/PlusJakartaDisplay-Regular.otf");
}
