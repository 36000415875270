body.app_home_merchant

    .header.mobile-fixed
        background-color: white
        border-bottom: 2px solid $borderDark
        .lh-menu
            color: $orange

    .container
        min-height: auto

    .background-merchant
        background-color: #225D7B
        width: 100%
        position: relative
        top: 0
        height: 580px
        @media (max-width: $screen-sm-max)
            background-position: 60% 100%
        .sticker-container
            display: flex
            position: absolute
            bottom: 10px
            flex-direction: column
            justify-content: flex-end
            align-items: flex-end
            align-content: flex-end
            @media (min-width: 1900px)
                right: 450px
            @media (min-width: 1300px) and (max-width: 1900px)
                right: 200px
            @media (max-width: 1300px)
                right: 10px
            .sticker
                width: 200px
                height: 200px
                background-size: contain
                background-repeat: no-repeat
                @media (max-width: 1300px)
                    width: 150px
                    height: 150px
                @media (max-width: $screen-sm-min)
                    width: 80px
                    height: 80px

    .background-merchant.lesaffre
        background-image: asset('img/partners/banner_lesaffre.jpg')
        background-position: none
        height: 500px
        @media (max-width: $screen-sm-max)
            background-position: 50% 100%
            height: 350px
        @media (max-width: $screen-sm-min)
            background-position: 50% 100%
            height: 230px
        .sticker-container
            .sticker
                background-image: asset('img/partners/sticker_price.png')

    #lh-logo.partner
        float: right
        margin-top: 30px
        margin-right: 40px
        width: 240px
        @media (max-width: $screen-sm-max)
            width: 170px
            margin-top: 10px

    .header
        background-color: transparent
        color: white
        top: -2px

    .header-links
        a
            color: white
            i
                margin-right: 5px

    ul.key-figures
        font-size: 25px
        padding-left: 0px
        margin-top: 20px
        color: $white
        li
            font-weight: 100
            background-image: asset('img/icons/check-orange.svg')
            padding-left: 50px
            list-style: none
            background-size: 30px
            background-repeat: no-repeat
            line-height: 45px
            background-position: 0px 8px
            text-transform: lowercase
            span
                text-transform: uppercase
                font-weight: 300

    .slogan-wrap
        padding: 0
        margin-top: 90px
        text-transform: uppercase
        .subtitle
            font-style: italic
            font-weight: 13px
            color: $white
        h1
            margin-top: 0px
            color: white
        .is-peach
            color: $peach

    .mui-wrap
        padding: 0
        .lh-button
            text-transform: none
            padding: 0 30px
        .lh-button.white
            background-color: white
            color: black
            border: 1px solid white
        .is-outlined::hover
            background-color: transparent
            color: white
    .mui--a+.mui--a
        margin-left: 10px
        @media (max-width: 330px)
            margin-left: 0

    .button-wrap
        padding: 0
        a:last-child
            margin-left: 25px
        button
            padding: 1px 12px
            font-weight: 300
            width: 190px

    .top
        .player-infos
            float: right
            p
                font-size: 14px
        .player-container
            float: left
            @media (max-width: $screen-sm-max)
                margin-top: 50px

    .bottom
        .player-container
            margin-top: 30px
            @media (max-width: $screen-sm-max)
                margin-top: 100px
            @media (max-width: $screen-xs-min)
                margin-top: 0

    .player
        margin-top: 80px
        padding: 0
        @media (max-width: $screen-sm-max)
            margin-top: 15px
        h2
            color: $orange
            font-weight: 300
            font-size: 30px
        p
            font-size: 12px
            font-weight: 300
            color: $gray
            margin-top: 10px

    .player-container
        padding: 0
        .smaller
            &:nth-child(1)
                border-right: 10px solid white
                @media (max-width: $screen-sm-max)
                    border-right: 0
                    margin-bottom: 100px
                @media (max-width: $screen-xs-min)
                    margin-bottom: 20px
            &:nth-child(2)
                border-left: 10px solid white
                @media (max-width: $screen-sm-max)
                    border-left: 0
            div.youtube-wrapper .youtube .play-button:before
                width: 50px
                height: 50px
            @media (max-width: $screen-sm-max)
                padding-bottom: 42.25%


    .thumbnail-container
        position: absolute
        top: 0
        width: 100%
        left: 0
        img
            width: 100%
            max-height: 371px
            @media (min-width: $screen-md-min) and (max-width: $screen-md-max)
                height: 310px

    .video-container
        position: relative
        padding-bottom: 30.25%
        padding-top: 30px
        height: 0
        overflow: hidden
        @media (max-width: $screen-sm-max)
            padding-bottom: 42.25%

    .video-container iframe,
    .video-container object,
    .video-container embed
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max)
        .thumbnail-container.smaller
            img
                height: 168px

    .start-button
        position: absolute
        padding: 12px
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        cursor: pointer
        transition: all 0.3s
        opacity: .7
        &:hover
            opacity: 1

    .testimony
        padding: 0

    .testimony-header
        margin-top: 20px
        padding: 0
        div:nth-child(1)
            text-align: center
        @media (max-width: $screen-sm-max)
            div:nth-child(3)
                margin-top: 20px
        @media (max-width: $screen-sm-min)
            margin-bottom: 20px
        @media (max-width: $screen-xs-min)
            div:nth-child(2)
                margin-top: 0

    .testimony-user
        font-size: 18px
        font-weight: 400
        color: $orange

    .testimony-title
        font-size: 11px
        font-weight: 300
        text-transform: uppercase
        color: $gray

    .testimony-content
        font-weight: 300
        font-size: 16px
        color: $gray
        @media (max-width: 375px)
            font-size: 12px

    .testimony-img
        height: 75px
        @media (max-width: $screen-xs-min)
            height: 60px

    .title-wrap
        margin-bottom: 30px
        border-bottom: 1px solid $gray
        padding: 10px

    .jumbo
        background-color: $borderLight
        height: 380px
        @media (max-width: $screen-sm-max)
            margin-top: 80px
        @media (max-width: $screen-sm-min)
            height: auto
        p
            font-size: 16px
            color: $gray
            font-weight: 300

    .jumbo-icons-container
        display: flex
        flex-direction: row
        padding: 0
        margin-top: 100px
        @media (max-width: $screen-sm-min)
            flex-direction: column
            margin-top: 0
        h2
            text-transform: none
            color: $orange
            margin-top: 0

    .fidelisation, .rapidite, .croissance
        flex-grow: 1
        text-align: center
        @media (max-width: $screen-sm-min)
            margin: 0 0 30px 0

    .fidelisation h2, .rapidite h2, .croissance h2
        margin-top: 15px

    .rapidite
        margin-top: -11px

    .fidelisation
        @media (max-width: $screen-sm-min)
            margin-top: 30px

    .mockup
        background-color: $orange
        @media (max-width: $screen-sm-max)
            text-align: center

    .mockup-title
        text-align: center
        color: white
        padding: 0
        margin: 20px 0 50px 0
        h2
            color: white
            text-transform: uppercase
        @media (max-width: 375px)
            h1
                font-size: 30px
        @media (max-width: 335px)
            h1
                font-size: 25px


    .background-ipad
        background: asset('img/app_screen/ipad-mockup.png')
        background-size: cover
        background-position: center
        height: 670px
        width: 964px
        float: none
        margin: 0 auto
        @media (max-width: $screen-md-max)
            height: 438px
            width: 629px
        @media (max-width: $screen-sm-min)
            height: 245px
            width: 350px
        @media (max-width: $screen-xs-min)
            height: 245px
            width: 350px
        @media (max-width: 375px)
            height: 220px
            width: 316px
        @media (max-width: 335px)
            height: 189px
            width: 271px

    .swiper-container
        height: 558px
        top: 35px
        width: 744px
        left: 7px
        @media (max-width: $screen-md-max)
            height: 374px
            top: 24px
            width: 484px
        @media (max-width: $screen-sm-min)
            height: 213px
            top: 13px
            width: 273px
            left: 3px
        @media (max-width: $screen-xs-min)
            height: 210px
            top: 15px
            width: 276px
        @media (max-width: 375px)
            height: 182px
            top: 12px
            width: 243px
            left: 3px
        @media (max-width: 335px)
            height: 156px
            top: 10px
            width: 210px
            left: 0


    .swiper-container-testimony
        height: 300px
        width: 310px
        overflow: hidden
        @media (max-width: $screen-sm-max)
            height: 210px
            width: auto
        @media (max-width: $screen-xs-min)
            width: 300px
            height: 330px
            .swiper-slide
                width: auto

    .testimony
        .swiper-pagination-bullet-active
            background: $orange

    .img-slide
        border-radius: 3px
        img
            width: 743px // 768
            height: 557px // 577
            @media (max-width: $screen-md-max)
                width: 482px
                height: 362px
            @media (max-width: $screen-sm-min)
                width: 270px
                height: 203px
            @media (max-width: $screen-xs-min)
                width: 267px
                height: 199px
            @media (max-width: 375px)
                height: 183px
                width: 250px
            @media (max-width: 335px)
                height: 158px
                width: 210px

    .swiper-pagination
        bottom: -50px
        left: 50%
        transform: translateX(-50%)
        z-index: 1

    .swiper-pagination.testimony
        bottom: 70px
        @media (max-width: $screen-sm-max)
            bottom: 0
        @media (max-width: $screen-xs-min)
            bottom: 70px

    .swiper-pagination-bullet
        margin-right: 10px

    .swiper-pagination-bullet-active
        background: white

    .presentation
        margin-top: 150px
        @media (max-width: $screen-sm-max)
            text-align: center
        @media (max-width: $screen-sm-min)
            margin-top: 55px
            &:nth-child(1)
                margin-top: 100px

    .presentation-text--float
        float: right

    .presentation-text
        color: white
        font-size: 16px
        @media (max-width: $screen-sm-max)
            margin-bottom: 50px
        p
            font-weight: 300
            margin-top: 20px

    .presentation-img
        @media (max-width: $screen-sm-max)
            text-align: center
        .iphone
            height: 450px
            width: 219px
            @media (max-width: $screen-sm-max)
                height: 400px
                width: 195px

    .presentation, .presentation-img
        img
            width: 530px
            @media (max-width: $screen-sm-min)
                width: 340px
            @media (max-width: $screen-xs-min)
                width: 285px
            @media (max-width: 375px)
                width: 220px

    .presentation-img--right
        text-align: right
        @media (max-width: $screen-sm-max)
            text-align: center

    .presentation-title
        color: white
        font-weight: 300
        line-height: 35px
        font-size: 35px

    .button-wrap
        margin: 50px 0
        text-align: center
        .mui-btn
            min-width: 240px
            font-size: 18px
        @media (max-width: 438px)
            button
                width: 100%
            a:last-child
                margin-left: 0

    .title
        text-align: center
        color: $orange
        font-weight: 300
        margin-bottom: 60px
        @media (max-width: $screen-xs-min)
            margin-bottom: 10px
        span
            font-size: 18px

    .flex-container
        display: flex
        flex-direction: column
        padding: 0 50px
        @media (max-width: $screen-xs-min)
            padding: 0

    .flex-item
        margin-bottom: 50px
        font-size: 14px
        color: $gray
        font-weight: 300
        display: flex
        flex-direction: row
        max-height: 100px

    .flex-info
        color: $orange
        font-weight: 300
        font-size: 35px
        @media (max-width: $screen-sm-min)
            font-size: 25px

    .flex-img
        min-width: 150px
        position: relative
        img
            height: 65px
            position: absolute
            right: 30px
            top: 50%
            transform: translateY(-50%)

    .banner
        background-color: $peach
        display: flex
        align-items: center
        min-height: 110px
        padding: 50px

    .banner-wrap
        color: white
        font-size: 25px
        font-weight: 300
        text-align: center
        @media (max-width: $screen-sm-min)
            font-size: 25px
        strong
            font-size: 25px

    .swiper-container-partners
        width: 100%
        overflow: hidden
        .swiper-slide
            text-align: center

    .swiper-pagination-partners
        position: absolute
        bottom: -50px
        left: 50%
        transform: translateX(-50%)
        .swiper-pagination-bullet-active
            background: $orange

    .partners
        padding: 70px 0 110px 0
        background: white
        @media (max-width: $screen-sm-max)
            padding: 50px 0
        .title
            h2
                margin-top: 0

    div.partner-list
        position: relative
        @media (max-width: $screen-sm-min)
            text-align: center
        div.swiper-wrapper
            img
                filter: grayscale(100%)
                width: 130px
                &#tdgroumande
                    width: 110px
                @media (min-width: $screen-md-max)
                    width: 145px
                @media (max-width: $screen-sm-min)
                    margin: 30px 30px 0 0
                &:hover
                    filter: none
                    cursor: pointer

    .form-wrap-top
        margin-top: 40px
        background-color: $white
        border-radius: 8px
        box-shadow: #9B9B9B 0px 3px 9px 0px
        .flash-notice
            margin: 0px 30px 30px
        p.form-title
            font-style: italic
            padding: 30px 20px 20px 20px
            text-align: center
            font-size: 33px
        .custom-input
            margin-bottom: 15px
            input
                background-color: $borderDark
                padding: 15px
                border: 0px
                width: 100%
            input:-webkit-autofill,
            textarea:-webkit-autofill,
            select:-webkit-autofill,
            input:-webkit-autofill:hover,
            textarea:-webkit-autofill:hover,
            select:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill:focus
              background-color: $borderDark
              -webkit-box-shadow: 0 0 0px 1000px $borderDark inset
              -webkit-text-fill-color: $gray !important

            ul
                margin-bottom: 0px
            @media (min-width: $screen-sm-max)
                &.lastname
                    padding-right: 8px
                &.firstname
                    padding-left: 7px


        .mui-select
            padding-top: 0px
            margin-bottom: 15px
            select
                font-size: 14px
                background-color: #ECECEC
                background-position: right 20px center
                padding: 0px 15px
                height: 50px
                border: 0px
                color: $gray
            select:valid
                color: $gray
        .subm-btn
            margin-bottom: 40px

        @media (max-width: $screen-sm-max)
            padding: 0px
            #top-form
                padding: 0px
            #contact_top_form_name_submit
                font-size: 12px


    .form-wrap-bottom
        .flash-notice
            border: 1px solid
            margin: 0px 30px 30px
        .custom-input
            margin-bottom: 15px
            input
                background-color: $white
                padding: 15px
                border: 0px
                width: 100%
            input::placeholder,
            input:-ms-input-placeholder,
            input::-ms-input-placeholder
                color: $gray
            ul
                margin-bottom: 0px
            @media (min-width: $screen-sm-max)
                &.lastname
                    padding-right: 8px
                &.firstname
                    padding-left: 7px

        .mui-select
            padding-top: 0px
            margin-bottom: 15px
            select
                font-size: 14px
                background-color: $white
                background-position: right 20px center
                padding: 0px 15px
                height: 50px
                border: 0px
                color: $gray
            select:valid
                color: $gray
        .subm-btn
            margin-bottom: 40px

    .form-wrap
        padding: 0
        form
            padding: 0

    .contact
        background-color: $borderLight
        padding: 40px 0
        .info-title
            color: $orange
            font-weight: 300
            font-size: 35px
            line-height: 35px
        .phone-number
            color: $peach
            font-weight: 300
            font-size: 16px
            padding: 0
            .fa
                margin-right: 15px
                color: $peach
        img
            max-height: 35px
            max-width: 35px
            margin-top: 5px
        span
            color: $gray
            font-weight: 300
            font-size: 16px
        .icons-wrap
            margin-top: 35px
            padding: 0
            span
                padding-right: 0
                margin-top: 5px
            div:last-child>span
                    margin-top: 10px
            .cash, .clock, .hand
                padding: 0
                margin-bottom: 20px

        .banner
            height: 170px
            .banner-wrap
                font-size: 25px
                strong
                    font-size: 25px

        .swiper-container-testimony
            height: 210px
            width: auto
            .swiper-slide
                width: auto
        @media (max-width: $screen-sm-max)
            padding: 30px 0px
            .form-wrap-bottom,
            #bottom-form,
            .contact-wrap
                padding: 0px
            .contact-info
                padding: 0px 15px 20px 15px
            #contact_bottom_form_name_submit
                font-size: 12px

    .iphone-text
        @media (max-width: 438px)
            font-size: 18px
