
div
    &.submenu
        .container-transactions,
        .container-invoices
            margin-left: calc(#{$sidebar_size} + #{$submenu_size})

        .header
            padding-left: 280px

    .container-transactions, .container-invoices
        padding-left: 30px
        padding-right: 30px

        .transactionsTable
            margin-top: 20px

        .ant-table
            .ant-table-row
                font-size: 14px

                &.search
                    left: 50%
                    transform: translate(-50%, -50%)

                > span
                    overflow: hidden
                    white-space: nowrap
                    text-overflow: ellipsis

                .payment_mode
                    text-transform: uppercase
                    font-size: 14px

                .debit, .credit
                    > .lh
                        font-size: 20px
                        margin-right: 5px
                        position: relative

                .debit
                    color: $negative

                .credit
                    color: $orange

    .ant-select-selection
        border-radius: 0px
        background-color: #FFFFFF
        height: 40px

        .ant-select-selection__rendered
            border-radius: 0px
            font-size: 14px
            line-height: 40px
            border: 0px

    ul.ant-select-tree
        .ant-select-tree-node-content-wrapper
            padding: 5px 5px

            &:hover
                font-weight: bold

            &.ant-select-tree-node-selected
                margin: 0px

    .filters__wrapper
        .payment-modes
            height: 100px

        .filter__title
            color: $orange
            font-weight: 400
            font-size: 14px
            margin-bottom: 5px

        .rangeDate__wrapper
            margin-bottom: 50px

            .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover
                background-color: $orange
                border-color: $orange

            .CalendarDay__selected_span, .CalendarDay__hovered_span
                background-color: #5EA2C7
                border-color: $orange
                color: white

            .DateInput_input__focused
                border-color: $orange

            .DateInput_input
                line-height: 16px
                font-size: 14px

    .transactions__autocomplete

        .lhd-form-control__input-text-prefix
            top: 38px

        .autocomplete__select
            margin-top: 10px

        .ais__results
            position: absolute
            width: 100%
            z-index: 1
            background: white

            .ais-Hits
                margin-top: 10px
                border-radius: 4px
                height: 180px
                overflow-y: auto
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1)
                -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1)
                -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1)

            .ais-Hits-item
                padding: 0

                &.highlighted
                    background-color: #E6F7FF

                .ais__item
                    cursor: pointer
                    display: flex
                    flex-direction: row
                    padding: 10px
                    align-items: center
                    border-bottom: 1px solid #EBEBEB
                    margin: 0

                    &__picture
                        width: 50px
                        border: 1px solid #EBEBEB
                        margin-right: 10px

                    &__infos
                        display: flex
                        flex-direction: column

                        &__name
                            font-weight: bold
                            font-size: 13px
                            letter-spacing: 0
                            color: #4A4A4A


                        .shop-card__details__address
                            font-size: 13px
                            font-weight: normal
                            letter-spacing: 0
                            color: #9C9C9C

                            .lh
                                font-size: 18px
                                color: #9B9B9B

    .filters__transac
        text-transform: uppercase
        color: $gray
        font-size: 16px
        float: right
        font-weight: 400
        line-height: 60px

        .nb
            color: $orange
            font-size: 20px
            margin-right: 10px

    .lh-button.is-outlined
        margin: 0

    .stat-icon
        margin-top: 40px !important

    .ant-input.search
        background-image: asset('img/dashboard/search.svg')
        background-position: 10px center
        background-repeat: no-repeat
        background-size: 20px
        padding-left: 40px
        font-size: 16px

#content_stat
    .conteneur_1_colonne
        width: 100% !important

        div.section
            float: left
            width: 100%

    h4
        text-align: left
        margin-left: 10px
        font-weight: 600
        margin-top: 10px

    h1
        font-size: 17px !important
        line-height: 24px
        font-weight: 400
        color: white
        margin: 0

    h6
        color: white
        margin: 0

    .stat_vignette2
        width: 18% !important
        padding: 15px
        margin-left: 10px !important
        float: left !important
        min-height: 100px

    .flex-col
        width: 100% !important
        margin-left: 10px
