.submenu
  background: white
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  position: fixed
  top: 70px
  width: 100%
  z-index: 5

  &__menu-toggler
    background: none
    border: none
    padding: 8px
    display: none
    margin-left: auto

    @media (max-width: $screen-md-max)
      display: block

      i
        pointer-events: none
        color: $gray

  .submenu__list
    display: flex
    flex-direction: row
    list-style: none
    margin: 0
    z-index: 1
    overflow-x: auto
    padding: 0 70px
    transform-origin: top
    transition: transform 0.2s

    @media (max-width: $screen-md-max)
      flex-direction: column
      transform: scaleY(0)
      position: absolute
      background: white
      width: 100%
      padding: 0 10px
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1)

      &.show
        transform: scaleY(1)

    li
      display: flex
      padding: 10px

      @media (max-width: $screen-md-max)
        padding: 2px

      .submenu__list-item
        align-items: center
        border-radius: 20px
        color: $gray
        cursor: pointer
        display: flex
        font-size: 13px
        font-weight: bold
        letter-spacing: 0.65px
        padding: 8px 10px
        text-decoration: none
        text-transform: uppercase
        white-space: nowrap
        line-height: 13px

        &.selected,
        &:hover
          background: $secondaryLight
