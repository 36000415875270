#login
    .header
        height: 60px
        top: 0
        z-index: 2
        border-bottom: 1px solid $borderDark
        img
            width: 170px
            margin: 11px 0
        @media (max-width: $screen-sm-max)
            position: fixed


    .header-links
        margin: 20px 0 15px 0
        font-size: 14px
        position: absolute
        right: 15px
        top: 0
        z-index: 2
        a
            margin-left: 20px
        li
            cursor: pointer

    .header-button
        margin-top: 20px
        border: 1px solid white
        background-color: transparent
        padding: 5px 8px

    .header-input
            height: 50px
            font-size: 14px
            line-height: 14px
            border: 0
            border-radius: 0
            vertical-align: top

    .fa-home, .fa-sign-out
        color: $gray
        font-size: 14px
        margin-right: 5px

    .fa-sign-out
        color: white

    .lh-menu
            display: none

    .open-menu-wrapper
        position: absolute
        text-align: center
        padding: 0
        top: -1px
        left: 0
        transition: height 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28)
        overflow: hidden
        height: 0
        background-color: white
        ul
            padding: 0
            list-style: none
        .list-menu
            margin-top: 50px
            padding: 0px 50px
        li
            margin-top: 20px
            color: $gray
            a
                color: $gray
        li.active
            font-weight: bold
        .fa-sign-out
            color: $gray
        .account-nav
            margin-bottom: 20px
            padding-bottom: 20px
            border-bottom: 2px solid $borderDark
            li
                color: $orange

    .open-menu-wrapper.opened
        height: 670px
        border-bottom: 2px solid $borderDark
        a
            color: $gray

    .fa-user-circle-o
        margin-right: 10px

    .open-menu-button
        border: 1px solid $gray
        padding: 5px

    .mobile-header-wrapper
        height: 50px
        margin-top: 15px
        img
            float: left
            margin: 0

    .mobile-footer-wrapper
        height: 50px
        border-top: 1px solid $orange

    .lh-cross
        color: $orange
        float: right
        font-size: 35px
        cursor: pointer
    .lh-menu
        font-size: 40px
        float: right
        margin-top: 11px
        color: white
        @media (max-width: $screen-sm-min)
            &:hover
                cursor: pointer


    @media (max-width: $screen-sm-max)
        .header-search, .header-select, .search-button
            margin-top: 5px
            border: 0

    @media (min-width: $screen-lg-min)
        .fa-search:before
            margin-right: 7px

    .container
        padding: 15px

    .input-search-list
        width: 100%
        height: 0
        position: absolute
        left: 0
        overflow: hidden
        top: 0
        background-color: white
        transition: height 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28)
        border-bottom: 2px solid $borderDark

    .input-search-list.search-opened
        height: 285px
        overflow: inherit

    .search-toggle-wrap
        position: absolute
        top: 10px
        color: $gray
        cursor: pointer
        border: 2px solid $borderDark
        padding: 8px
        width: 75%
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        i
            margin-right: 10px

    #grey-select
        background-color: $borderLight
    .bg-lightgrey
        background-color: $borderLight

    .search-button
        height: 50px
        border-radius: 0
        border: 0
        font-size: 14px
        text-transform: uppercase
        background-color: $orange
        color: white

    .input-search-wrap
        margin-bottom: 50px
        color: $gray
        i
            float: left
            margin-top: 5px

        span
            font-size: 16px
            font-weight: 300
            float: right
