.filter__list
  display: flex
  flex-direction: row
  flex-wrap: wrap
  float: left

  .filter__list-item
    background-color: $orange
    border-radius: 10px
    color: white
    list-style: none
    margin: 0 5px
    margin-bottom: 10px
    padding: 10px

    strong
      margin-left: 8px

    .filter__list-item-close
      color: rgba(255, 255, 255, 0.5)
      cursor: pointer
      font-weight: 400
      margin-left: 13px

      &:hover
        color: white
