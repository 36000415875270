$black: #000
$white: #fff
$border: #E0E0E0
$borderDark: #E0E0E0
$borderLight: #EBEBEA
$cell: #F6F6F6
$negative: #C23838
$facebook: #1877F2
$light: #FFFFFF
$linkedin: #0E76A8
$pipedrive: #203232
$primary: #15A4B0
$primaryLight: #5BBFC6
$primaryLightPlus: #D7F0F3
$secondary: #EEBE91
$secondaryLight: #FDECDD
$secondaryLightPlus: #FBF7F1
$text: #3D3D3D
$textLight: #727272
$up: #F39219
$success: #15B078
$bianca: #FCF8F3
$lh: #EBBA1B
$disabled: #b1b1b1
$dark: #0F1A26
$gray: #6E6E6E
$beige: #EFECE1
$orange: #EBBA1B
$yellow: #FEDE31
$peach: #FBCC33
$green: #7DC2AF
$darkGreen: #3DAEA9
$blue: #5EBEC4
$grayBlue: #C2D7E3


$primary-font: 'Plus Jakarta Display', sans-serif
$fontawesome: FontAwesome

$sidebar_size: 70px
$submenu_size: 180px

$navbar_size: 70px

$dashboard_url: 'http://localhost:3000'

$equipment_border: 2px

$assets_cdn: 'https://static.leshabitues.fr/'
