.material-checkbox
  color: $gray
  display: block
  line-height: 18px
  position: relative

  > input
    appearance: none
    appearance: none
    appearance: none
    background-color: rgba(0, 0, 0, 0.42)
    border-radius: 50%
    display: block
    height: 48px
    left: -15px
    margin: 0
    opacity: 0
    outline: none
    position: absolute
    top: -15px
    transform: scale(0)
    transform: scale(1)
    transition: opacity 0.5s, transform 0.5s
    width: 48px
    z-index: -1

    &:checked
      background-color: $orange

  &:active > input
    opacity: 1
    transform: scale(0)
    transition: opacity 0s, transform 0s

  >
    input:disabled
      opacity: 0

    + span
      cursor: not-allowed !important

    span::before
      border: solid 2px greyIcons
      content: ''
      display: inline-block
      height: 18px
      margin-right: 15px
      transition: border-color 0.5s, background-color 0.5s
      vertical-align: -3px
      width: 18px

    input
      &:checked + span::before
        background-color: $orange
        border-color: $orange

      &:active + span::before
        border-color: $orange

      &:checked:active + span::before
        background-color: rgba(0, 0, 0, 0.42)
        border-color: transparent

      &:disabled + span::before
        border-color: rgba(0, 0, 0, 0.26)

      &:checked:disabled + span::before
        background-color: rgba(0, 0, 0, 0.26)
        border-color: transparent

    span::after
      border: solid 2px transparent
      border-left: none
      border-top: none
      content: ''
      display: inline-block
      height: 10px
      left: 1px
      position: absolute
      top: 2px
      transform: translate(5.5px, 2px) rotate(45deg)
      transform: translate(5.5px, 1px) rotate(45deg)
      width: 5px

    input:checked + span::after
      border-color: #FFFFFF

  input:disabled + span
    cursor: not-allowed
