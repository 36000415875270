.filters__wrapper
  .filter__section
    margin-top: 30px

    &.radio
      margin-top: 8px

    &.filter-slider
      > a
        color: $gray
        cursor: pointer
        position: absolute
        top: 50px

      &:not(:first-of-type)
        margin-top: 50px

      .range-label
        color: $orange
        font-weight: 400
        margin: 0 10px

        .range-label__max
          color: $negative
          font-style: normal

    &.filter-buttons
      margin-top: 40px

    .lh-button
      padding: 7px

    .filter__section-title
      color: $orange
      font-size: 14px
      font-weight: 400

    .mui-select > select
      color: $gray
      font-weight: 300

    .mui-select__menu
      max-height: 200px !important
      top: 15px !important

    .radios-container
      margin-top: 15px

      .radio-wrap
        .selected::before
          height: 12px
          left: 2px
          top: 2px
          width: 12px

    .rc-slider
      margin-top: 20px

      .rc-slider-rail,
      .rc-slider-track
        height: 2px

      .rc-slider-handle
        border: none
        margin-top: -6px

        &.rc-slider-handle-1
          background-color: rgba(26, 120, 168, 1)

        &.rc-slider-handle-2
          background-color: rgba(254, 98, 98, 1)

    .lh-plus_circle
      color: $orange
      cursor: pointer
      font-size: 20px
      margin-left: 5px
      margin-top: -3px
      position: absolute
      top: 3px
      transform: rotate(45deg)

    #ggle-results--container
      position: absolute
      top: 75px

      .ggle-results--item,
      .as-results--item
        background-color: white
        border-bottom: 1px solid E6E6E6
        color: $gray
        cursor: pointer
        font-size: 11px
        font-size: 13px
        line-height: 30px
        overflow: hidden
        padding: 6px 12px
        text-align: left
        text-overflow: ellipsis
        white-space: nowrap
        z-index: 2

        &:focus
          background-color: $borderLight
          outline: none

        &:hover
          background-color: $borderLight

        &:hover > .ggle-icon
          background-image: asset('img/icons/map/icon_map_blue.png')

        &:hover > .as-icon
          background-image: asset('img/icons/shop-draw.svg')

      .focus
        background-color: $borderLight

        > .as-icon
          background-image: asset('img/icons/shop-draw.svg')

        > .ggle-icon
          background-image: asset('img/icons/map/icon_map_blue.png')

      div.category
        background-color: $borderLight
        font-weight: normal
        padding: 6px 12px

    .ggle-icon
      background-image: asset('img/icons/map/icon_map_grey@3x.png')
      background-position: center 0%
      background-repeat: no-repeat
      background-size: cover
      display: inline-block
      height: 16px
      margin-bottom: -3px
      margin-left: 3px
      margin-right: 7px
      pointer-events: none
      width: 10px

.input-range
  margin-left: 5px
  margin-top: 15px
  width: 95%

  .input-range__track
    color: $gray
    height: 2px

  .input-range__slider
    height: 2rem
    margin-top: -11px
    width: 2rem

  .input-range__label--value
    top: 1.2rem

  .input-range__track--active
    background: linear-gradient(90deg, $orange 0%, $negative 100%)

  .input-range__slider-container:first-of-type
    > .input-range__slider
      background: $orange
      border: none

    > .input-range__label--value > .input-range__label-container
      color: $orange
      font-size: 12px
      letter-spacing: 2px

  .input-range__slider-container:last-of-type
    > .input-range__slider
      background: $negative
      border: none

    > .input-range__label--value > .input-range__label-container
      color: $negative
      font-size: 12px
      letter-spacing: 2px

  .input-range__label--max > span,
  .input-range__label--min > span
    display: none

.filter__section-day
  &:last-of-type
    margin-top: 50px

  .section-day--inputs
    display: flex
    flex-direction: row

  .react-datepicker__input-container
    input
      border: none
      border-bottom: 1px solid grey
      margin-right: 10px
      margin-top: 25px
