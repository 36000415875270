.navbar + .lhd-page
  margin-top: 70px

.navbar + .submenu + .lhd-page
  margin-top: 120px

.lhd-page
  display: flex
  flex-direction: column
  height: 100vh
  overflow: auto
  padding: 20px 0
  width: 100%

  .header-filters .lhd-header
    padding: 0
    margin: 0

  .lhd-header
    margin-bottom: 20px
    width: 100%
    padding: 0 20px

    .shop-card
      display: flex

      &__logo
        border: 1.25px solid #F0F0F0
        height: 60px
        margin-right: 17px
        object-fit: contain
        width: 60px

      &__details
        display: flex
        flex-direction: column

        &__name
          color: #5E5E5E
          font-size: 16px
          font-weight: 300

        &__title
          color: #4A4A4A
          font-size: 36px
          font-weight: 300
          letter-spacing: 0
          line-height: 36px
          margin: 0

          &__title
            white-space: nowrap

    &__plus-button
      background-color: #EBBA1B
      border: none
      border-radius: 50%
      color: white
      display: block
      height: 50px
      margin-left: auto
      padding: 0
      width: 50px

      &.alternative
        background-color: transparent
        border: none
        box-shadow: none
        color: $gray

        .lh
          font-size: 35px

    .title
      color: $gray
      font-size: 36px
      font-weight: 300
      letter-spacing: 0

  .lhd-content
    position: relative

    .lhd-card
      background-color: white
      margin-bottom: 20px
      padding: 20px
      position: relative

      &__header-button
        align-items: center
        display: flex
        position: absolute
        right: 0
        top: 0
        margin: 20px

        &__label
          margin-right: 10px

      &__title
        color: $gray
        display: inline-block
        font-size: 24px
        font-weight: 300
        letter-spacing: 0
        margin: 0

      &__actions
        float: right

        &__action
          background-color: #EEF9FF
          border: none
          border-radius: 4px
          color: $orange
          cursor: pointer
          font-size: 14px
          font-weight: normal
          letter-spacing: 2px
          min-width: 120px
          outline: none
          padding: 4px
          text-align: center
          text-transform: uppercase

          &.loading
            cursor: wait



      &__subtitle
        color: $gray
        font-size: 13px
        font-weight: bold
        letter-spacing: 0.65px
        margin: 0 0 20px
        text-transform: uppercase

      &__paragraph
        color: $gray
        font-size: 16px
        font-weight: 300
        margin: 0 0 30px

      &__content
        &__actions
          display: flex
          justify-content: flex-end
          margin-left: auto

          &__button
            background-color: transparent
            border: none
            border-radius: 50%
            display: block
            height: 36px
            margin-left: 10px
            outline: none
            padding: 0
            width: 36px

            img
              width: 16px

            .lh
              font-size: 20px

            &.blue
              border: 1px solid $orange
              color: $orange

            &.red
              border: 1px solid $negative
              color: $negative

            &.yellow
              border: 1px solid $peach
              color: $peach

        &__informations
          margin-bottom: 20px

          &__label
            color: $orange
            display: block
            font-size: 14px
            font-weight: 500
            letter-spacing: 0
            line-height: 14px

          &__text
            color: $gray
            display: block
            font-size: 16px
            font-weight: 300
            line-height: 16px
            margin-top: 10px

        &__informations-big
          background-color: $secondaryLightPlus
          border-radius: 8px
          display: inline-block
          margin-bottom: 20px
          padding: 20px
          width: 270px

          &__label
            color: $gray
            display: block
            font-size: 10px
            font-weight: bold
            letter-spacing: 0.5px
            line-height: 10px
            text-transform: uppercase

          &__text
            color: $orange
            display: inline-block
            font-size: 36px
            font-weight: 300
            letter-spacing: 0
            line-height: 36px
            position: relative

          &__upper-text
            display: inline-block
            font-size: 13px
            font-weight: bold
            height: 13px
            position: absolute
            right: -20px
            text-align: right
            text-transform: uppercase
            top: -22px

            &::after
              border-top: 1px solid $gray
              content: ''
              left: 0
              position: absolute
              right: 0
              top: 16px

  .ant-table-wrapper
    .ant-table-container
      .ant-table-sticky-header
        top: -20px !important
        padding: 0px 20px

        .ant-table-thead
          .ant-table-cell
            color: $gray
            font-size: 13px
            font-weight: bold
            letter-spacing: 0.65px
            text-transform: uppercase

            i
              color: inherit

      .ant-table-body
        .ant-table-tbody
          .ant-table-row
            td
              font-weight: normal

              &.id
                color: $gray
                font-weight: bold
                text-align: center

                &.left
                  text-align: left


    .ant-table-content
      .ant-table-thead
        .ant-table-cell
          color: $gray
          font-size: 13px
          font-weight: bold
          letter-spacing: 0.65px
          text-transform: uppercase

          i
            color: inherit

      .ant-table-tbody
        .ant-table-row
          td
            font-weight: normal

            &.id
              color: $gray
              font-weight: bold
              text-align: center

              &.left
                text-align: left

  .ant-table-fixed-left,
  .ant-table-fixed-right
    .ant-table-thead
      .ant-table-header-column
        .ant-table-column-title
          color: $gray
          font-size: 13px
          font-weight: bold
          letter-spacing: 0.65px
          text-transform: uppercase

          i
            color: inherit

.lhd-form
  .lhd-form-control
    display: flex
    flex-direction: column
    margin: 30px 0 0
    position: relative
    width: 100%
    background: white

    @media (max-width: $screen-sm-max)
      margin: 20px 0 0

    &.first
      margin-top: 0

    &.no-flex
      display: block

      .lhd-form-control__label
        margin-left: 10px
        vertical-align: middle

    &__label
      color: $gray
      font-size: 10px
      font-weight: bold
      letter-spacing: 0.5px
      text-transform: uppercase

      &.has-error
        color: $negative

    &__input-text,
    &__textarea,
    &__autocomplete,
    &__input-number,
    &__datepicker,
    &__input-password,
    &__rangepicker
      background-color: transparent
      border: 1px solid $borderDark !important
      border-radius: 4px
      color: $gray
      font-size: 16px
      font-weight: 300
      outline: none
      padding: 15px 10px
      width: 100%

      &:focus
        border-color: $orange
        transition: border 0.5s

      &.has-error
        border: 1px solid red2 !important
        color: $negative !important

      &:disabled, &.disabled
        border: none
        color: $gray
        cursor: default !important

        &::placeholder
          color: transparent

    &__textarea
      &:disabled, &.disabled
        height: auto

    &__input-text,
    &__autocomplete,
    &__input-number,
    &__input-password
      height: 50px

      &.has-prefix
        padding-left: 44px

    &__input-time
      border: 1px solid $borderDark
      //width: 100%
      border-radius: 4px
      height: 50px

      .ant-picker-input
        font-size: 16px
        padding: 0

    &__datepicker
      height: 50px
      padding: 8px 10px

      &.ant-picker-focused
        border: 1px solid 1A78A8
        box-shadow: none

      input
        border: none

    &__rangepicker
      background-color: transparent
      color: $gray
      font-size: 16px
      font-weight: 300
      outline: none
      padding: 8px

      .ant-input
        border: none

      .ant-picker-active-bar
        background: $orange

      &.ant-picker-focused
        box-shadow: none

    &__input-number
      padding: 10px

      input
        border: none

      .ant-input-number-input-wrap
        input
          padding: 0

      &.offer-amount-big
        height: 75px
        padding: 10px

        .ant-input-number-input-wrap
          height: 100%

          input
            font-size: 50px
            height: 100%
            line-height: 50px
            padding: 0

    &__textarea
      &:disabled
        height: auto
        min-height: 30px
        resize: none

    &__input-text-prefix
      color: $gray
      display: block
      left: 10px
      position: absolute
      top: 30px

      img
        width: 28px

    &__input-button
      background: #fdecdd
      border-radius: 4px
      border: none
      outline: none
      position: absolute
      right: 12px
      top: 26px
      color: #ebba1b
      font-size: 12px
      font-weight: normal
      letter-spacing: 2px
      line-height: 16px
      text-transform: uppercase
      padding: 5px

      &:disabled
        color: rgba(0, 0, 0, 0.25)
        background: #f5f5f5

    &__input-password-toggle
      background: none
      border: none
      outline: none
      padding: 0
      position: absolute
      right: 12px
      top: 28px

    &__select
      border: 1px solid $borderDark
      border-radius: 4px
      color: $gray
      min-height: 50px

      &:hover
        .ant-select-selector
          border: none
          box-shadow: none

      .ant-select-selector
        border: none
        height: 50px
        padding: 0 10px

      &.ant-select-single
        .ant-select-selection-search-input
          height: 100%

        .ant-select-selection-item,
        .ant-select-selection-placeholder
          line-height: 50px

      .ant-select-selection--multiple
        min-height: 48px

        .ant-select-selection__rendered
          line-height: 50px

      &.has-error
        border: 1px solid red2 !important
        color: $negative !important

      .ant-select-selection
        background-color: transparent
        border: none
        min-height: 50px
        padding: 0

        .ant-select-selection__rendered
          line-height: 50px

        .ant-select-selection__placeholder
          margin-left: 0

      &.ant-select-disabled
        color: $gray
        cursor: not-allowed

        .ant-select-selection
          background-color: transparent
          cursor: not-allowed

        .ant-select-arrow
          display: none

    &__autocomplete
      &__suggestions
        background-color: white
        border: 1px solid $borderDark
        border-radius: 4px
        bottom: -40px
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15)
        margin-top: 5px
        position: absolute
        width: 100%
        z-index: 1

        li
          color: $gray
          cursor: pointer
          padding: 5px

          &:hover
            background-color: $borderDark
            color: black
            transition: background-color 200ms linear

    &__upload-image
      .ant-upload-select-picture-card
        height: 100%
        width: 100%

      img
        height: 200px
        object-fit: contain

    &__error
      bottom: -20px
      color: $negative
      font-size: 13px
      font-weight: normal
      left: 0
      letter-spacing: 0
      margin-left: 10px
      position: absolute

    &__paragraph
      color: $gray
      font-size: 16px
      font-weight: 300
      white-space: pre-line

    &__description
      color: $gray
      font-size: 13px
      font-weight: normal
      letter-spacing: 0

    &__radio-group
      display: block

      &.direction-row
        display: flex
        flex-direction: row

        > div + div
          margin-left: 30px

      &.direction-column
        display: flex
        flex-direction: column

      &.has-error
        .ant-radio-wrapper
          .ant-radio
            .ant-radio-inner
              border-color: $negative

    &__radio
      margin-top: 10px

      .ant-radio
        .ant-radio-input
          height: 20px
          width: 20px

        .ant-radio-inner
          border-color: $gray
          height: 20px
          width: 20px

          &::after
            background-color: $orange
            height: 12px
            width: 12px


        & + span
          color: $gray
          font-size: 16px
          font-weight: 300

    &__checkbox-group
      display: block

    &__checkbox
      margin-left: 0
      width: 100%
      display: flex
      align-items: center

      & + &
        margin-bottom: 20px

      &__center
        margin: auto

      .ant-checkbox
        .ant-checkbox-input
          height: 20px
          width: 20px

        .ant-checkbox-inner
          border-color: $gray
          height: 20px
          width: 20px

          &::after
            background-color: $orange
            height: 10px
            left: 26%
            top: 50%
            width: 6px


        & + span
          color: $gray
          font-size: 16px
          font-weight: 300
          padding-left: 8px
          padding-right: 8px
          margin-top: -3px

        &.ant-checkbox-disabled
          .ant-checkbox-inner
            background-color: white

          &.ant-checkbox-checked
            .ant-checkbox-inner
              background-color: $gray

              &::after
                background-color: $gray
                border-color: #FFFFFF


  //&.disabled
  //    .lhd-form-control__label
  //        color: $orange
  //        font-size: 14px
  //        text-transform: initial
  //        letter-spacing: 0


  &__submit
    background-color: $orange
    border-radius: 4px
    color: white
    font-size: 14px
    font-weight: normal
    height: 50px
    letter-spacing: 2px
    padding: 0
    text-align: center
    text-transform: uppercase
    width: 100%

    &:disabled
      color: rgba(0, 0, 0, 0.25)
      background: #f5f5f5

    &:hover
      background-color: lighten($orange, 10%)
      border-color: lighten($orange, 10%)
      color: white

  &__btn
    border-radius: 4px
    color: white
    display: block
    font-size: 14px
    font-weight: normal
    height: 50px
    letter-spacing: 2px
    margin: auto
    padding: 0 15px
    text-align: center
    text-transform: uppercase
    width: 100%

    &__danger
      background-color: $negative
      border-color: $negative
      color: #FFFFFF

      &:hover
        background-color: darken($negative, 10%)
        border-color: darken($negative, 10%)
        color: #FFFFFF

      &:active,
      &:focus
        background-color: #FFFFFF
        border-color: $negative
        color: $negative


    &__secondary
      background-color: transparent
      border-color: $orange
      color: $orange

      &:hover
        color: lighten($orange, 10%) !important
        border-color: lighten($orange, 10%) !important

    &__primary
      background-color: $orange
      border-color: $orange
      color: $white

    &__grey
      border-color: $gray
      color: $gray

    &__outlined
      background: transparent

.lhd-not-found
  left: 50%
  position: absolute
  top: 50%
  transform: translate(-50%, -50%)

  span
    color: $orange
    display: block
    font-size: 60px
    font-weight: bold
    letter-spacing: 1px
    line-height: 54px
    text-align: center
    text-transform: uppercase

  h1
    text-align: center

  h2
    color: $orange
    text-align: center

.layout
  & + .lhd-not-found
    display: none

span.small
  color: $gray
  font-size: 10px

.ant-menu-submenu
  .ant-menu-item
    height: auto
    line-height: initial

.shop-card
  display: flex

  &__logo
    height: 50px
    width: 50px

  &__description
    display: flex
    flex-direction: column
    font-size: 13px
    height: 50px
    letter-spacing: 0
    line-height: 13px
    margin-left: 10px
    padding-top: 10px

    &__name
      color: #4A4A4A
      font-weight: bold

    &__infos
      color: #9C9C9C

.notification-error-code
  .ant-notification-notice-description
    span
      display: block

      &.code
        font-size: 10px
        text-align: right

.lhd-form-control__input-time__popup
  .ant-time-picker-panel-input-wrap
    height: 50px
    padding: 15px 10px

    .ant-time-picker-panel-input
      font-size: 16px

.lhd-form-control__checkbox + .lhd-form-control__checkbox
  margin-top: 20px

.ant-menu-submenu-popup
  border-radius: 4px !important
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1) !important
  max-height: 400px !important
  overflow-y: auto !important
  top: 168px !important

  ul
    max-height: 400px !important

    li
      margin: 0 !important
      padding: 10px !important

      &:hover
        background-color: #E6F7FF

      & + li
        border-top: 1px solid lightGrey !important

.ant-picker-cell-selected,
.ant-picker-cell-range-start
  .ant-picker-cell-inner
    background: $orange !important

.lhd-tooltip
  .ant-tooltip-content
    .ant-tooltip-arrow
      .ant-tooltip-arrow-content
        background-color: $orange

    .ant-tooltip-inner
      background-color: $orange

      .payment-detail
        .payment-mode
          display: flex

          i
            display: block
            font-size: 18px
            margin-right: 5px

          span
            display: flex
            align-items: center

          &.cashback
            border-top: 1px solid $orange
            padding-top: 5px

.ant-picker-panels
  @media (max-width: $screen-xs-max)
    flex-direction: column

.ant-checkbox-group
  width: 100% !important

  .ant-checkbox-wrapper
    display: flex
    justify-content: center

.ant-slider-track
  background-color: $orange !important

.ant-slider-handle
  border-color: $orange !important

.grecaptcha-badge
  bottom: 84px !important
