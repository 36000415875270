#login
    min-height: 100vh
    background-color: white

    .logo
        margin-top: 80px
        display: block
        margin-left: auto
        margin-right: auto
        width: 400px

        @media (max-width: $screen-xs-max)
            margin-top: 20px

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
        border: 1px solid $borderDark
        -webkit-text-fill-color: $gray !important
        -webkit-box-shadow: none
        transition: background-color 5000s ease-in-out 0s
        color: $gray
        font-size: 16px


    .lhd-form
        margin-top: 80px

        @media (max-width: $screen-xs-max)
            margin-top: 40px

        @media (max-width: $screen-md-max)
            margin-bottom: 50px

        @media (max-width: $screen-xs-max)
            margin-left: 20px
            margin-right: 20px

        h2
            font-size: 36px
            font-weight: 300
            line-height: normal
            color: $orange
            margin: 0
            letter-spacing: 0

        &__error
            background-color: $negative
            padding: 10px
            border-radius: 4px
            box-shadow: 0  2px 4px 0 rgba(0, 0, 0, 0.2)
            -webkit-box-shadow: 0  2px 4px 0 rgba(0, 0, 0, 0.2)
            display: flex
            align-items: center
            margin-top: 20px

            p
                color: white
                font-size: 13px
                letter-spacing: 0
                line-height: 13px
                margin: 0 0 0 12px

        button[type="submit"]
            background-color: $orange
            font-size: 14px
            font-weight: normal
            text-transform: uppercase
            letter-spacing: 2px
            padding: 7px 10px
            box-shadow: none
            height: 50px
            width: 100%
            text-align: center
            border: none
            color: $white
            border-radius: 4px

            &:hover:not(:disabled)
                background-color: lighten($orange, 10%)

            &:disabled
                opacity: 0.5

        .reset-password
            display: block
            color: $gray
            font-size: 16px
            font-weight: 300
            margin-top: 50px

        .lhd-form-control__checkbox
            margin: 30px 0

        .fa-spinner
            color: white
            display: table
            margin: 15px auto auto


        .form-error, .input-error
            font-size: 12px
            color: #B90D00
            margin-top: 8px
            font-weight: 400

        .input-error
            position: absolute
            margin: 0

    .sidebar-wrapper
        height: 100vh
        background-color: $orange
        display: flex
        flex-direction: column

        @media (max-width: $screen-xs-max)
            height: initial

        .register-bloc
            padding: 40px

            @media (max-width: $screen-xs-max)
                padding: 20px



        .register-bloc
            h2
                font-size: 36px
                font-weight: 300
                line-height: normal
                color: $white
                margin: 0
                letter-spacing: 0

            .ant-btn
                border-radius: 4px
                border: 1px solid $borderDark
                height: 50px
                background-color: transparent
                padding: 10px
                color: $white
                font-size: 14px
                text-transform: uppercase
                letter-spacing: 2px
                span
                    display: inline-block


            a.text
                margin-top: 20px
                margin-bottom: 30px
                color: white
                display: block
                font-size: 16px
                font-weight: normal

            hr
                height: 1px

            .availability
                display: flex
                flex-wrap: wrap
                justify-content: space-around

                a
                    display: block
                    margin-bottom: 10px

                img
                    width: 130px
    .login-img
        flex: 1
        overflow: hidden
        display: flex
        align-items: end

        @media (max-width: $screen-xs-max)
            display: none

        img
            object-fit: contain
            width: 100%
            padding: 20px
