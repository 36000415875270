.modal
  background: white
  left: 50%
  position: absolute
  top: 50%
  transform: translate(-50%, -50%)

  &:focus
    outline: none

  &.offset
    left: 60%

.overlay
  background-color: rgba(0, 0, 0, 0.5)
  bottom: 0
  left: 0
  position: fixed
  right: 0
  top: 0

.modal__top
  padding: 20px 40px 0

  &.opac
    opacity: 0

label.ant-form-item-required
  &::after
    color: #F5222D

.equipment-modal--return,
.equipment-modal--delete
  background-color: #FE6262
  padding: 0
  padding-bottom: 0 !important

  .return-form,
  .delete-form
    .ant-form-item-label label
      color: $white
      font-size: 10px
      font-weight: bold
      letter-spacing: 0.5px
      text-transform: uppercase

    label.ant-form-item-required
      &::after
        color: $white

  span > .ant-input,
  div > .ant-select-selection
    border-color: $white
    color: $white

  .has-error .ant-form-explain
    color: $white

  .ant-modal-content
    box-shadow: none
    padding: 0 !important

  .ant-modal-content,
  .ant-modal-header
    background: #FE6262
    border: none
    border-bottom: none

  .ant-modal-header
    padding: 40px 40px 0

    .ant-modal-title
      background: #FE6262
      color: $white
      line-height: initial

  .ant-modal-body
    color: $white
    font-weight: 300
    letter-spacing: 0
    padding: 20px 40px 45px

    .equipment-name
      font-size: 24px

    .equipment-description
      font-size: 16px
      margin-bottom: 40px

  .ant-modal-footer
    background: #FE6262
    border: none
    border-top: none
    padding: 40px

    .btn-stock--cancel
      background-color: #FE6262 !important
      color: $white

    .btn-stock--submit
      margin: 0 !important

.equipment-modal--assign
  padding-bottom: 0 !important

  .ant-modal-content
    background: $white
    padding: 0 !important

    .ant-modal-header
      background: $borderLight
      border-bottom: none
      padding: 40px 40px 0

    .ant-modal-body
      background: $borderLight
      padding: 0
      padding-top: 25px

      .equipment-info
        margin: 0
        padding: 0 40px 40px

        .equipment-name
          color: $orange
          font-size: 24px
          font-weight: 300
          height: 29px
          letter-spacing: 0
          margin: 0

      .assign-form
        background: $white
        margin: 0
        padding: 40px

        ul#as-results--container
          li
            color: #262626

    .ant-modal-footer
      background: $white
      border-top: none
      margin: 0
      padding: 0 40px 40px 0

      .btn-stock--cancel
        border: 1px solid blue
        color: $orange

        .equipment-name
          background: $borderLight
          color: $orange
          font-size: 24px
          font-weight: 300
          height: 29px
          letter-spacing: 0
          margin: 0

.equipment-modal--history--loading
  .ant-modal-content
    background-color: rgba(255, 255, 255,0)
    box-shadow: none

.equipment-modal--history
  .ant-modal-content
    padding: 0!important
    background: $white

    .ant-modal-header
      padding: 40px 40px 0 40px
      background: $borderLight
      border-bottom: none

      .ant-modal-title
        line-height: initial

    .ant-modal-body
      padding: 0
      background: $white

      .equipment-info
        background: $borderLight
        margin-left: -30px
        margin-right: -30px
        padding-bottom: 40px
        padding-left: 30px
        padding-right: 30px

        .equipment-name
          background: $borderLight
          margin: 0
          color: $orange
          font-size: 24px
          font-weight: 300
          height: 29px
          letter-spacing: 0px

  .equipment-history
    .author
      margin-top: 10px
      img
        width: 18px
        vertical-align: middle
        margin: 0 5px 0 0
      span
        line-height: 24px
        color: $gray
        font-size: 9px
        font-weight: normal
        text-transform: uppercase

    height: 50vh
    overflow: auto
    padding: 10px 0

    .history-spinner
      text-align: center

    .history-row
      display: flex
      flex-direction: column

      .history-left
        position: relative
        flex-grow: 1
        align-self: flex-start
        width: calc(50% + #{$equipment_border})
        border-right: $equipment_border dotted $gray

        &:before
          content: ''
          background: $green
          border-radius: 10px
          width: 15px
          height: 15px
          display: block
          position: absolute
          right: -8px

      .history-right
        position: relative
        flex-grow: 1
        align-self: flex-end
        width: calc(50%)
        color: $gray
        border-left: $equipment_border dotted $gray

        &:before
          content: ''
          background: $negative
          border-radius: 10px
          width: 15px
          height: 15px
          display: block
          position: absolute
          left: -8px

      .history-content
        background-color: $borderLight
        padding: 20px
        border-radius: 10px
        margin: 25px

        .updated-field-title
          font-weight: bold

        .history-header
          display: flex
          flex-direction: row
          margin-bottom: 10px

          .history-date
            align-self: flex-start
            flex-grow: 9
            color: $gray
            font-size: 10px
            font-weight: bold
            text-transform: uppercase
            letter-spacing: 0.5px
            line-height: 27px
          .history-status
            align-self: flex-end
            flex-grow: 1
            font-size: 10px
            font-weight: bold
            text-align: center
            text-transform: uppercase
            letter-spacing: 1px
            padding: 5px
            color: $gray
            border: 1px solid $gray
            border-radius: 5px

            &.status-0,
            &.status-1,
            &.status-12
              border: 1px solid $peach
              color: $peach
            &.status-2
              border: 1px solid $green
              color: $green
            &.status-3,
            &.status-4,
            &.status-10,
            &.status-11
              border: 1px solid $orange
              color: $orange
            &.status-5,
            &.status-6,
            &.status-7,
            &.status-8,
            &.status-9
              border: 1px solid $negative
              color: $negative

      .field
        list-style: none
        padding: 0
        margin-bottom: 0

      .history-right,
      .history-left

        &.status-0:before,
        &.status-1:before,
        &.status-12:before
          background-color: $peach

        &.status-2:before
          background-color: $green

        &.status-3:before,
        &.status-4:before,
        &.status-10:before,
        &.status-11:before
          background-color: $orange

        &.status-5:before,
        &.status-6:before,
        &.status-7:before,
        &.status-8:before,
        &.status-9:before
          background-color: $negative

.ant-modal,
.ant-modal.delete-modal
  .ant-modal-content
    background-clip: padding-box
    background-color: #FFFFFF
    border: 0
    border-radius: 0
    box-shadow: none
    box-shadow: none
    pointer-events: auto
    position: relative

    .ant-modal-close
      right: 30px
      top: 30px

      .ant-modal-close-x
        border: 1px solid $orange
        border-radius: 50%
        color: $orange
        display: table
        font-size: 30px
        font-weight: lighter
        height: 38px
        line-height: 34px
        width: 38px

        .lh
          display: table-cell
          font-size: 20px
          text-align: center
          vertical-align: middle

    .ant-modal-header
      border: none
      padding: 30px 80px 30px 30px

      .ant-modal-title
        color: $gray
        font-size: 35px
        font-weight: 300
        line-height: 35px

    .ant-modal-body
      padding: 0 30px

    .ant-modal-footer
      border: none
      padding: 30px
      text-align: right

  &.delete-modal
    .ant-modal-content
      background-color: $negative

    .ant-modal-close
      .ant-modal-close-x
        border: 1px solid white
        color: white

    .ant-modal-header
      background: transparent
      border: none
      padding: 30px 80px 30px 30px

      .ant-modal-title
        color: white
        font-size: 35px
        font-weight: 300
        line-height: 35px

    .ant-modal-body
      padding: 0 30px

    .ant-modal-footer
      border: none
      padding: 30px
      text-align: right

      .footer-modal
        .lhd-form__btn__secondary
          background-color: transparent
          border-color: white
          color: white

.ant-modal-confirm
  .ant-modal-content
    padding: 20px

    .ant-modal-body
      padding: 0
