.navbar
    background: $orange
    z-index: 6
    display: flex
    flex-direction: row
    position: fixed
    width: 100%

    @media (max-width: $screen-md-max)
        flex-direction: column

    .mobile-menu
        display: flex
        justify-content: space-between
        align-items: center

        &__menu-toggler
            border: none
            background: none
            color: white
            margin-right: 0

            i
                font-size: 32px
                pointer-events: none

            @media (min-width: $screen-lg-min)
                display: none

    &__wrapper
        transform-origin: top
        transition: transform 0.2s
        display: flex
        flex-direction: row
        width: 100%

        @media (max-width: $screen-md-max)
            position: absolute
            top: 70px
            width: 100%
            background: $orange
            transform: scaleY(0)
            flex-direction: column

            &.open
                transform: scaleY(1)

    &__list
        margin: 0
        padding: 0
        display: flex
        flex-direction: row

        @media (max-width: $screen-md-max)
            flex-direction: column

    .top-list
        flex: 1

    .bottom-list
        bottom: 0

    &__list-item
        list-style: none
        position: relative
        padding: 15px
        display: flex

        @media (max-width: $screen-md-max)
            padding: 5px

        span
            color: white
            font-size: 13px
            font-weight: bold
            text-transform: uppercase
            letter-spacing: 0.65px
            margin-left: 5px
            line-height: 13px

        .lh
            font-size: 30px
            color: white
            cursor: pointer
            display: block

            &:hover
                color: white

            &.selected
                color: white

            &.lh-Arrow-Chevron-Down
                margin-left: 5px
                font-size: 14px

        &.logo
            padding: 8px

            img
                width: 42px

            .navbar__list-anchor
                padding: 6px
                border-radius: 8px

    &__list-anchor
        padding: 5px 10px
        display: flex
        align-items: center
        cursor: pointer
        border-radius: 20px

        &.selected, &:hover
            background: lighten($orange, 10%)

            @media (max-width: $screen-lg-min)
                background: none

        &.dropdown
            .dropdown-content
                display: none
                position: absolute
                right: 20px
                top: 55px
                cursor: default

                @media (max-width: $screen-md-max)
                    position: relative
                    right: 0
                    top: 0

                ul
                    list-style: none
                    background: white
                    z-index: 1
                    border-radius: 4px
                    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1)
                    width: max-content
                    padding: 0 20px
                    margin: 15px 0 0

                    @media (max-width: $screen-md-max)
                        background: transparent
                        box-shadow: none
                        margin: 0
                        padding: 0

                    li
                        display: flex
                        align-items: center
                        color: $gray
                        padding: 20px 0
                        cursor: pointer

                        @media (max-width: $screen-md-max)
                            color: white
                            padding: 5px 0

                        .lh, span
                            color: inherit

                        .lh
                            font-size: 24px

                        span
                            margin-left: 20px
                            font-size: 16px
                            font-weight: 300
                            text-transform: initial
                            letter-spacing: initial

                        & + li
                            border-top: 1px solid $borderDark

                            @media (max-width: $screen-md-max)
                                border: none

                        &:hover
                            color: $orange

                        .navbar__list-anchor
                            padding: 0
                            display: flex
                            align-items: center
                            cursor: pointer
                            border-radius: 0
                            background: transparent
            &:hover
                .dropdown-content
                    display: block

            @media (max-width: $screen-md-max)
                >i
                    display: none

                .dropdown-content
                    display: block

    #dashboard_search
        width: $navbar_size
        height: $navbar_size
        display: inline-block
        background: center no-repeat asset('img/dashboard/navbar/dashboard_search_icon_grey.svg')

        &:hover
            background: center no-repeat asset('img/dashboard/navbar/dashboard_search_icon_white.svg')

    .navbar__list-item > .navbar__icon
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        cursor: pointer

    .search
        width: $navbar_size
        height: $navbar_size
        background-color: transparent
        position: relative
        overflow: hidden
        transition: all 0.5s ease
        cursor: pointer

    .search.open
        width: 300px

        > #dashboard_search
            background: $borderDark center no-repeat asset('img/dashboard/navbar/dashboard_search_icon_white.svg')

    .navbar__list-search
        width: 200px
        height: 60px
        position: absolute
        top: 0
        box-shadow: none
        border: 2px solid $borderDark
        border-left: none
        background: white
        color: $borderDark
        font-size: 25px
        display: block
        margin-left: $navbar_size
        padding: 0 0 0 15px
        -webkit-appearance: none

        &:focus
            outline: none

    #lh_stock
        height: 70px
        width: 70px
        display: inline-block
        background: 50% no-repeat url(https://static.leshabitues.fr/img/dashboard/navbar/dashboard_stock_icon_grey.svg)



.tooltip.blue
    background: $orange
    border-radius: 3px
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5)
    padding: 10px
    text-align: center
    color: white
    z-index: 2

    .tooltip-arrow
        border-color: $orange
        width: 0
        height: 0
        border-style: solid
        position: absolute
        margin: 5px

    .tooltip-inner
        white-space: nowrap
        font-weight: 300

.tooltip[x-placement^="right"]
    margin-left: -7px

    .tooltip-arrow
        border-width: 5px 5px 5px 0
        border-left-color: transparent
        border-top-color: transparent
        border-bottom-color: transparent
        left: -5px
        top: calc(50% - 5px)
        margin-left: 0
        margin-right: 0

.ant-popover

    &.popover-navbar
        left: 50px !important

    .ant-popover-content
        .ant-popover-arrow
            border-color: $orange

        .ant-popover-inner
            background-color: $orange

            .ant-popover-inner-content
                padding: 6px 10px
                color: white
                white-space: pre-line
