@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/normalize"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/utilities"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/grid"
@import "~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding"
@import '~antd/dist/antd'
@import "./_var"
@import "./_functions"
@import "./loader"
@import "../css/fonts"
@import "./general"
@import "./layout/navbar_navigation"
@import "./reactTable"
@import "./shops"
@import "./header"
@import "./dashboard_header"
@import "./home_merchant"
@import "./filters"
@import "./custom_inputs/checkbox_radio"
@import "./spinner"
@import "./modal"
@import "./switch"
@import "./layout/submenu"
@import "./materialCheckBox"
@import "./shop/account"
@import "./shop/supplies"
@import "./shop/shipment"
@import "./transactions"
@import "./filtersTool"
@import "./equipments"
@import "./login"
@import "./onboarding"
@import "./lhd_dashboard"

body
  background-color: #f7f7f7 !important

input[type=text]:focus
  appearance: none
  outline: none

input[type=text]::-ms-clear
  display: none
  height: 0
  width: 0

input[type=text]::-ms-reveal
  display: none
  height: 0
  width: 0

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration
  display: none

input[disabled]
  cursor: not-allowed !important

.pad-left-0
  padding-left: 0

.mui-btn.is-blue
  border: none
  border-radius: 0
  height: 50px
  transition: all 0.2s linear

  &:hover,
  &:focus
    background: rgba(26, 120, 168, 0.9)
    box-shadow: none
    transition: all 0.2s linear

.mui-btn--fab
  background: $orange
  box-shadow: none
  color: white
  font-size: 40px
  font-weight: 300
  letter-spacing: 0
  line-height: 50px
  transition: all 0.2s linear
  width: 50px

  &:hover,
  &:focus
    background-color: $orange
    color: white
    opacity: 0.9
    transition: opacity 0.2s linear

.lh-button.is-outlined
  box-sizing: border-box


.CalendarMonth_caption
  font-size: 14px
  line-height: 26px
