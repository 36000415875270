*
    font-family: $primary-font !important
    font-weight: 400

body
    font-family: $primary-font !important
    font-weight: 400
    letter-spacing: .03em
    box-sizing: border-box

a
    text-decoration: none !important
    color: inherit

h1
    font-weight: 300
    line-height: 54px
    font-size: 45px
    letter-spacing: 1px
    text-transform: uppercase
    color: $orange
    -webkit-font-smoothing: antialiased
    @media (max-width: $screen-sm-max)
        font-size: 40px
    @media (max-width: $screen-sm-min)
        line-height: 45px
    @media (max-width: $screen-xs-max)
        font-size: 35px
    @media (max-width: $screen-xs-min)
        font-size: 30px
        line-height: 35px

h2,
h2.header-title
    font-weight: 300
    font-size: 35px
    -webkit-font-smoothing: antialiased
    @media (max-width: $screen-xs-max)
        font-size: 30px
    @media (max-width: $screen-xs-min)
        font-size: 25px

h3
    font-size: 16px

strong
    font-weight: 400

h4
    &.empty-table
        text-align: center
        padding: 40px
        background: $white
        margin: 0px

.lh
    vertical-align: middle
    font-size: 25px
    color: inherit

form
    ul
        padding-left: 0

        li
            list-style: none
            color: $negative
            font-size: 12px
            padding: 5px 0px

strong
    font-weight: bold

.container
    min-height: calc(100vh - 450px)
    @media (max-width: $screen-sm-max)
        min-height: calc(100vh - 550px)
    @media (max-width: 340px)
        min-height: calc(100vh - 600px)

div.flash-notice
    padding: 25px
    color: $orange
    margin-bottom: 30px
    background-color: #EDF9FF
    border-radius: 10px
    font-weight: 400

    a
        color: $orange
        text-decoration: underline

    &:before
        font-family: $fontawesome
        content: '\f00c'
        margin-right: 5px

.flash-notice.error
    color: $negative
    background-color: $negative

    a
        color: $negative
        text-decoration: underline

    &:before
        content: '\f05e'

.alert-danger
    background-color: $negative
    border-color: transparent
    color: $negative
    border-radius: 0px
    text-align: center
    padding: 15px
    margin-bottom: 20px

.mui-btn
    height: auto
    letter-spacing: .15em

.btn
    border-radius: 0

.lh-button
    padding: 7px 10px
    text-transform: uppercase
    border: 0
    box-shadow: none
    border-radius: 0

    &:hover
        background-color: transparent
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

.button.is-orange
    background-color: $peach
    color: white
    border: 1px solid $peach
    -webkit-transition: background-color .1s ease-in
    -ms-transition: background-color .1s ease-in
    transition: background-color .1s ease-in

    &:hover
        background-color: $peach
        color: white
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

    &:focus
        background-color: $peach
        color: white
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

.is-blue
    background-color: $orange
    color: white
    border: 1px solid $orange
    -webkit-transition: background-color .1s ease-in
    -ms-transition: background-color .1s ease-in
    transition: background-color .1s ease-in

    &:hover
        background-color: $orange
        color: white
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

    &:focus
        background-color: $orange
        color: white
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

    &.is-darkblue
        background-color: $orange

.is-outlined--white
    background-color: transparent
    border: 1px solid white
    color: white

    &:focus
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)
        background-color: transparent
        color: white

    a,
    &:hover
        background-color: transparent
        color: white
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

.is-outlined--red
    background-color: transparent
    border: 1px solid $negative
    color: $negative

    &:focus
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)
        background-color: transparent
        color: $negative

    a,
    &:hover
        background-color: transparent
        color: $negative
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

.is-outlined
    background-color: transparent
    border: 1px solid $orange
    color: $orange

    a,
    &:hover
        color: $orange
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

    &:focus
        color: $orange
        box-shadow: 0 0 2px rgba(0, 0, 0, .12), -3px 3px 2px rgba(0, 0, 0, .1)

.center
    margin: 0 auto
    float: none

.container-fluid
    padding: 0

.nopadding
    padding-left: 0px
    padding-right: 0px

.pad-0
    padding: 0

.loading:after
    position: absolute
    display: block
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: $borderLight
    content: " "
    z-index: 9
    opacity: 0.5

input:focus, select:focus
    outline: none

input::-webkit-input-placeholder
    color: $gray

input:-moz-placeholder
    color: $gray
    opacity: 1

input::-moz-placeholder
    color: $gray
    opacity: 1

input:-ms-input-placeholder
    color: $gray

input::-ms-clear
    display: none

select::-ms-expand
    display: none

input:required
    box-shadow: none

.form-control::-moz-placeholder
    color: $gray
    opacity: 1

.form-control:-ms-input-placeholder
    color: $gray

.form-control::-webkit-input-placeholder
    color: $gray

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control
    background-color: transparent

fieldset[disabled] .btn.dropdown-toggle
    opacity: 1

[type="search"]
    -webkit-appearance: textfield
    -webkit-box-sizing: content-box

.clearable-input
    position: relative

    button[type="reset"].clear
        background: transparent
        border: 0
        position: absolute
        right: 10px
        top: 50%
        transform: translateY(-50%)
        color: $gray
        display: none

        &:focus
            outline: none

    input:valid ~ button[type="reset"].clear
        display: block

.select-icon
    position: relative
    padding: 0

    &:after
        content: "\F0D7"
        font-family: $fontawesome
        position: absolute
        color: $orange
        top: 50%
        transform: translateY(-50%)
        font-size: 20px
        font-weight: normal
        right: 20px
        pointer-events: none

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
select:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus
    background-color: #FFFFFF
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset
    -webkit-text-fill-color: $gray !important

.mui-textfield, .mui-select
    font-weight: 300

.mui-textfield > input:focus ~ label, .mui-textfield > textarea:focus ~ label, .mui-select:focus > label, .mui-select > select:focus ~ label
    color: $orange

.mui-textfield > input:focus, .mui-textfield > textarea:focus, .mui-select:focus > select
    border-color: $orange

.mui-textfield--float-label > label
    color: $gray
    font-weight: 300

.mui-select__menu > div.mui--is-selected, .mui-select__menu > div:not(.mui-optgroup__label):not(.mui--is-disabled):hover
    background-color: $borderLight

.mui-select__menu
    padding: 0

/*.ant-notification
  left: 50%
  top: 0
  transform: translate(-50%, 0%)
  bottom: auto
  top: 8px
  .ant-notification-notice
    width: 580px
    .ant-message-notice-content
      width: 580px
      */

.anticon-close
    border-color: red !important
    color: red !important
    cursor: pointer

.icon-tab
    margin-right: 7px

.ReactTable.loading
    .rt-table
        pointer-events: none
        user-select: none
        overflow: hidden
        -webkit-filter: blur(0.5px)
        filter: blur(0.5px)


.ant-table
    .ant-table-thead > tr.ant-table-row-hover > td,
    .ant-table-tbody > tr.ant-table-row-hover > td,
    .ant-table-thead > tr:hover > td,
    .ant-table-tbody > tr:hover > td
        background-color: #F7F7F7

    .action-table
        width: 150px

    .ant-table-body
        padding: 0 20px
        background-color: white

        .ant-table-row
            background-color: white

        tr
            td.regular-table
                font-weight: 500

            td
                font-size: 15px
                color: rgb(74, 74, 74)
                font-weight: 300

        tr.ant-table-row.deleted
            background-color: #FDFDFD

            td
                color: #CCC

    .ant-table-thead
        tr
            th
                font-size: 15px
                padding: 10px
                letter-spacing: 1.5px
                font-weight: 200
                background: white
                color: #9B9B9B
                text-transform: uppercase

                .fas
                    font-size: 20px

                .far
                    font-size: 20px

    .ant-table-tbody > tr > td
        padding: 5px 10px

    &#shops-table
        .ant-table-container
            .ant-table-sticky-header, .ant-table-body
                padding: 0

.ant-pagination
    width: 100%
    margin: 15px auto !important
    text-align: center

    a
        color: #9B9B9B
        font-weight: 400

    li
        border-radius: 100%
        background: #F7F7F7

    .ant-pagination-item-link
        border-radius: 100%
        background: #F7F7F7
        border: none

    .ant-pagination-jump-next
        .ant-pagination-item-ellipsis
            opacity: 1

    .ant-pagination-jump-next:hover
        .ant-pagination-item-ellipsis
            opacity: 1
            color: #1890FF

.ant-checkbox
    .ant-checkbox-input:focus + .ant-checkbox-inner
        border-color: #D9D9D9

    &:hover
        .ant-checkbox-inner
            border-color: #EBBA1B !important

    &.ant-checkbox-indeterminate
        .ant-checkbox-inner
            background-color: #EBBA1B
            border: none

            &::after
                width: 8px
                height: 2px
                background-color: #FFFFFF

    &.ant-checkbox-checked
        &::after
            border: none

        .ant-checkbox-inner
            border: none
            background-color: #EBBA1B

div > .ant-input, span > .ant-input, .ant-input-number-input, div > .ant-select-selection
    border: 0
    border-bottom: 1px solid rgba(0, 0, 0, .26)
    border-radius: 0
    background: rgba(0, 0, 0, 0)
    transition: ease-in 0.5s

    &:focus, &::selection, &:hover
        border: 0
        border-bottom: 2px solid #EBBA1B
        box-shadow: none

.ant-form-item
    .ant-form-item-label
        label
            color: $orange
        font-weight: 600
        font-size: 12px
        line-height: 12px

    .ant-form-item-control-wrapper
        height: 0

.ant-pagination-prev.ant-pagination-disabled, .ant-pagination-next.ant-pagination-disabled
    color: $borderDark !important

.ant-pagination > .ant-pagination-prev, .ant-pagination > .ant-pagination-next
    color: $orange
    border: 0
    background: rgba(0, 0, 0, 0)

    ::after
        font-size: 30px

.ant-pagination > .ant-pagination-item
    border: 0
    background: rgba(0, 0, 0, 0)
    color: black

.ant-pagination > .ant-pagination-item-active
    border: 1px solid $orange
    color: $orange
    background: rgba(0, 0, 0, 0)

.ant-checkbox-wrapper + span, .ant-checkbox + span
    padding: 0

.ant-modal
    width: 800px
    overflow-x: hidden
    padding: 0

.ant-modal-centered .ant-table-body
    word-break: normal
    white-space: normal !important

.ant-modal-content
    background-color: #F0F0F0

    .ant-confirm-body .ant-confirm-title
        font-weight: 300


.SingleDatePicker
    top: -15px

    .SingleDatePickerInput
        border-bottom: 1px solid rgba(0, 0, 0, .26)

        .DateInput
            min-width: 220px

            .DateInput_input
                padding: 11px 11px 9px 0px
                font-size: 16px

.layout
    display: flex
    flex-direction: column
    overflow: hidden
    height: 100vh

.ant-switch-checked
    background-color: $orange
