.custom--spinner
  background-color: transparent
  height: 100%
  position: absolute
  width: 100%
  z-index: 999

  .spinner
    animation: rotator 1.4s linear infinite
    left: 50%
    position: fixed
    top: 50%
    transform: translate(-50%, -50%)

    .path
      animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite
      stroke-dasharray: 187
      stroke-dashoffset: 0
      transform-origin: center
