.shipment__view
  font-weight: 400

  .block-header
    padding: 20px

    .modal-toggler
      float: right

    .ReactTable
      .table-item
        color: $gray
        font-size: 14px
        font-weight: 200

        .table-item__description,
        .table-item__name
          font-weight: 400
