@keyframes rotate
  100%
    transform: rotate(1turn)
    -webkit-transform: rotate(1turn)

div[class*='LoadingWheel'], .loading-wheel
  position: relative
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  width: 50px
  height: 50px
  z-index: 0
  border-radius: 10px
  overflow: hidden
  padding: 14px
  display: flex !important
  align-items: center
  justify-content: center

  &:before
    content: ''
    position: absolute
    z-index: 1
    left: -50%
    top: -50%
    width: 200%
    height: 200%
    background-color: transparent
    background-repeat: no-repeat
    background-size: 50% 50%, 50% 50%
    background-position: 0 0, 100% 0, 100% 100%, 0 100%
    background-image: linear-gradient(#F7F7F7, #F7F7F7), linear-gradient(#EBBA1B, #EBBA1B), linear-gradient(#EBBA1B, #EBBA1B), linear-gradient(#EBBA1B, #EBBA1B)
    -webkit-animation: rotate 1s cubic-bezier(0.47, 0.07, 0.45, 1.01) infinite
    animation: rotate 1s cubic-bezier(0.47, 0.07, 0.45, 1.01) infinite


  &:after
    content: ''
    position: absolute
    z-index: 2
    left: 2px
    top: 2px
    width: calc(100% - 4px)
    height: calc(100% - 4px)
    background: #F7F7F7
    border-radius: 8px


  .css-logo
    z-index: 3
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    width: 16px
    height: 16px
    background-image: url("data:image/svg+xml,%3Csvg width='24px' height='27px' viewBox='0 0 24 27' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E %3Ctitle%3ECombined Shape%3C/title%3E %3Cdesc%3ECreated with Sketch.%3C/desc%3E %3Cg id='Design-System' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E %3Cpath d='M4.48216832,-4.79616347e-14 C6.22045712,-4.79616347e-14 7.62977944,1.4105918 7.62977944,3.14997871 L7.62977944,6.26581511 C7.62977944,7.85881102 8.91994487,9.15013859 10.5115071,9.15013859 L14.464245,9.15013859 C19.3314537,9.15013859 23.2767151,13.0989538 23.2767151,17.9705467 L23.2767151,23.7345166 C23.2767151,25.4743712 21.86786,26.884963 20.1295712,26.884963 L18.9805249,26.884963 C17.2422361,26.884963 15.8333811,25.4743712 15.8333811,23.7345166 L15.8333811,19.7557685 C15.8333811,17.5343783 14.0885598,15.7206705 11.8958926,15.6127886 L11.731,15.608 L11.5672679,15.6127886 C9.37460075,15.7206705 7.62977944,17.5343783 7.62977944,19.7557685 L7.62977944,23.7345166 C7.62977944,25.4743712 6.22045712,26.884963 4.48216832,26.884963 L3.14761112,26.884963 C1.40932232,26.884963 -1.95399252e-14,25.4743712 -1.95399252e-14,23.7345166 L-1.95399252e-14,3.14997871 C-1.95399252e-14,1.4105918 1.40932232,-4.79616347e-14 3.14761112,-4.79616347e-14 L4.48216832,-4.79616347e-14 Z M11.7741029,15.6077119 L11.6890576,15.6077119 L11.6890576,15.6077119 L11.731,15.608 L11.7741029,15.6077119 Z M19.583599,0.346240421 C21.6330976,0.346240421 23.2942849,2.00845636 23.2942849,4.05933336 C23.2942849,6.11021037 21.6330976,7.77289401 19.583599,7.77289401 C17.535035,7.77289401 15.8738477,6.11021037 15.8738477,4.05933336 C15.8738477,2.00845636 17.535035,0.346240421 19.583599,0.346240421 Z' id='Combined-Shape' fill='%231A78A8' /%3E %3C/g%3E %3C/svg%3E")
