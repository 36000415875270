.account__view
  margin-left: calc(#{$sidebar_size} + #{$submenu_size})
  padding: 30px

  .account__section
    background-color: white
    font-size: 16px
    margin-bottom: 40px
    padding: 30px

    &.last
      margin-bottom: 80px

    .section-title
      i
        font-size: 20px

    .mui-textfield
      color: $gray
      padding-left: 0

    input::placeholder
      color: $gray

    .custom-textarea
      border-color: $gray
      padding: 15px
      resize: none
      width: 100%

      &:focus,
      &:active
        outline: none

      &:disabled
        cursor: not-allowed

    .account__upload-file,
    .account__upload-desc
      margin-bottom: 25px
      margin-top: 25px

    .account__upload-file
      background-color: $borderLight
      border-radius: 10px
      height: 170px
      padding: 15px

      .uploader
        background-color: white
        border-radius: 10px
        height: 100%
        position: relative

        &.banner
          background-color: transparent
          padding: 0

        .uploader-content
          height: 100%

          .uploader-label
            cursor: pointer
            left: 50%
            position: absolute
            text-align: center
            top: 50%
            transform: translate(-50%, -50%)
            z-index: 0

            span
              color: $orange
              float: left
              font-size: 10px
              font-weight: 400
              letter-spacing: 2px
              margin-top: 10px
              text-transform: uppercase

          .uploader-input
            cursor: pointer
            height: 100%
            opacity: 0
            overflow: hidden
            padding-right: 0
            position: absolute
            z-index: 1

        &:hover
          > .uploader-content > .uploader-label > span
            color: $orange

    .account__upload-desc
      padding-left: 40px

      .uplaod-preview
        margin-top: 15px

        .lh-button
          font-size: 12px
          font-weight: 400
          margin-top: 20px
          padding: 10px 15px
          text-decoration: none
          text-transform: none

          .fa-desktop
            margin-right: 5px

    .link-input
      > input
        padding-left: 30px

      &::before
        bottom: 2px
        font-size: 20px
        left: 2px
        position: absolute

      &.fb
        &::before
          content: '\f09a'

      &.insta
        &::before
          content: '\f16d'

      &.web
        &::before
          content: '\f0c1'

    .radios-container,
    .checkboxes-container
      margin-top: 20px

      > .col-xs-4
        &:first-of-type
          padding-left: 0

      .nfc_description
        margin-top: 20px

      .material-checkbox
        margin-bottom: 10px

  .account__sticky_button
    bottom: 0
    left: calc(#{$sidebar_size} + #{$submenu_size})
    position: fixed
    right: 0

    .button
      margin-bottom: 0

  .custom--spinner
    background: white
    margin-top: 30%
    position: relative

  .submitting_circular
    color: white
    left: 50%
    position: absolute
    top: 25%
