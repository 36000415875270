.supplies__view
  font-weight: 400
  margin-left: calc(#{$sidebar_size} + #{$submenu_size})
  padding: 30px

  .ReactTable
    .shop-table-item
      color: $gray
      font-size: 16px

      .shop-table-item__amount
        font-weight: 300
        padding-bottom: 10px

        > .amount
          color: $orange
          font-size: 35px

        > .coupon
          font-size: 14px

      .shop-table-item__name
        font-weight: 300

        > .name
          font-weight: 400

        > div
          float: left

          > .public
            color: $orange
            display: inline

          > .desc
            color: $gray
            display: inline

      .shop-table-item__gift
        color: $orange

  .modal-wrap
    margin-bottom: 20px

    .modal-toggler
      float: right

      .mui-btn
        float: right

      span
        color: $orange
        display: block
        float: left
        font-weight: 400
        margin-right: 15px
        margin-top: 24px

.offer__modal
  width: 700px

  .lh-button
    border-radius: 5px
    bottom: 80px
    cursor: pointer
    position: absolute
    right: 35px

  .col-xs-6.right
    padding-left: 40px

  .mui-textfield
    margin-bottom: 30px

  .offer
    input
      color: $orange
      font-size: 40px
      font-weight: 400
      height: 45px

    &::after
      bottom: -6px
      color: $orange
      content: '\20AC'
      font-size: 40px
      font-weight: 300
      left: 140px
      position: absolute

  .radio-title
    color: $gray
    font-size: 16px
    font-weight: 400
    margin-bottom: 15px

    &:last-of-type
      margin-top: 30px

  .radio-wrap
    margin-bottom: 10px

  .modal__bottom
    margin-top: 50px

    &.has_margin
      margin-top: 300px

  .ReactTable
    bottom: 150px
    height: 200px
    position: absolute
