.step1
  background: white
  height: 100vh

  &__left
    background: $orange
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: space-between

    @media (max-width: $screen-xs-max)
      order: 2

    &__sidebar
      width: 100%
      margin-top: 100%

      @media (max-width: $screen-xs-max)
        display: none

    &__cover
      width: 100%

    &__subscribe
      padding: 40px
      position: absolute
      bottom: 0

      @media (max-width: $screen-xs-max)
        position: relative

      &__text
        color: white
        font-size: 18px
        font-weight: bold
        letter-spacing: 0px
        margin-bottom: 30px

      &__login
        border-radius: 4px
        border: 1px solid white
        height: 50px
        background-color: transparent
        padding: 10px
        color: white
        font-size: 14px
        text-transform: uppercase
        letter-spacing: 2px

      hr
        margin-top: 40px
        margin-bottom: 40px

      &__availability
        display: flex

        &__android
          margin-left: 20px

  &__right
    @media (max-width: $screen-xs-max)
      order: 1
      padding: 10px

    &__title
      color: $orange
      font-size: 36px
      font-weight: 300
      letter-spacing: 0px
      margin: 80px 0 20px
      font-family: "NT Wagner", sans-serif !important

      @media (max-width: $screen-xs-max)
        margin: 20px 0 20px

    &__subtitle
      color: $dark
      font-size: 16px
      font-weight: 300
      margin: 0 0 30px

    &__card
      background: rgb(255, 255, 255)
      border-radius: 8px
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2)
      overflow: hidden
      margin-bottom: 20px

      &__description
        padding: 20px
        display: flex
        flex-direction: row
        align-items: flex-end
        justify-content: space-between

        &__content
          &__title
            color: $dark
            font-size: 18px
            font-weight: bold
            letter-spacing: 0
            margin: 0

          &__text
            color: $gray
            font-size: 15px
            font-weight: 500
            letter-spacing: 0
            margin: 0

        &__icon
          font-size: 20px
          color: $gray
          font-weight: normal

.step2, .step3, .step4, .step5
  background: white
  border-right: 1px solid #eeeeee

  .cgv-link
    color: $orange
    font-weight: normal

  &__right
    &__accompanied
      @media (max-width: $screen-xs-max)
        padding: 10px

      &__header
        display: flex
        justify-content: center

        &__wrapper
          &__back
            color: $orange
            display: flex
            align-items: center
            font-size: 16px

            &:hover
              color: lighten($orange, 10%) !important

            i
              margin-right: 5px

          &__back + &__title
            margin-top: 15px

          &__title
            color: $orange
            font-size: 36px
            font-weight: 300
            letter-spacing: 0px
            margin: 80px 0 20px
            font-family: "NT Wagner", sans-serif !important

            @media (max-width: $screen-xs-max)
              margin: 20px 0 20px

          &__subtitle
            color: $dark
            font-size: 16px
            font-weight: 300
            margin: 0

      &__calendly
        width: 1000px
        height: 665px

        @media (max-width: $screen-xs-max)
          width: 100%
          height: 820px



  &__left
    background: white
    border-right: 1px solid #eeeeee
    height: 100vh

    @media (max-width: $screen-xs-max)
      order: 2
      height: auto

    &__alone
      &__logo
        width: 154px
        display: block
        margin: 80px auto 92px

        @media (max-width: $screen-xs-max)
          display: none

      &__breadcrumb
        padding: 0 40px

        @media (max-width: $screen-xs-max)
          padding: 20px

        &__link
          color: $gray
          font-size: 18px
          font-weight: bold
          letter-spacing: 0px
          display: flex
          align-items: center
          margin-bottom: 20px
          line-height: 18px

          span
            margin-left: 45px

          &.active
            color: $dark

            span
              margin-left: 20px

      &__footer
        padding: 40px
        border-top: 1px solid #eeeeee
        position: absolute
        bottom: 0
        width: 100%

        @media (max-width: $screen-xs-max)
          position: relative

        &__login
          display: flex

          span
            color: $dark
            font-size: 16px
            font-weight: 300

          a
            margin-left: 20px
            color: $orange
            font-size: 16px
            font-weight: 300

  &__right
    height: 100vh
    overflow-y: auto
    padding: 40px 0

    @media (max-width: $screen-xs-max)
      order: 1
      padding: 10px
      height: auto

    &__header
      display: flex

      &__wrapper
        &__back
          color: $orange
          display: flex
          align-items: center
          font-size: 16px

          i
            margin-right: 5px

        &__back + &__title
          margin-top: 15px

        &__title
          color: $orange
          font-size: 36px
          font-weight: 300
          letter-spacing: 0px
          margin: 40px 0 20px
          font-family: "NT Wagner", sans-serif !important

          @media (max-width: $screen-xs-max)
            margin: 20px 0 20px

        &__subtitle
          color: $dark
          font-size: 16px
          font-weight: 300
          margin: 0 0 30px

          @media (max-width: $screen-xs-max)
            margin: 0 0 10px


.step3, .step4
  &__right
    &__countdown
      color: $dark
      font-size: 16px
      font-weight: 300

    &__header
      &__wrapper
        width: 100%
        &__overtitle
          color: $dark
          font-size: 13px
          font-weight: bold
          text-transform: uppercase
          letter-spacing: 0.65px

        &__phone
          display: flex
          align-items: baseline
          justify-content: space-between

          &__text
            color: $dark
            font-size: 16px
            font-weight: 300

          &__button
            background: #eef9ff
            border: none
            border-radius: 4px
            color: $orange
            font-size: 14px
            font-weight: normal
            text-transform: uppercase
            letter-spacing: 0px

.step4
  &__right
    .google-wrapper
      background: white
      height: 310px
      position: relative
      width: 100%
      margin-top: 5px
      border-radius: 4px
      overflow: hidden

    &__supplies-desc
      color: $gray
      font-size: 13px
      font-weight: normal
      letter-spacing: 0px

    &__supplies
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 20px
      margin-top: 20px

      @media (max-width: $screen-xs-max)
        grid-template-columns: 1fr

      &__offer
          background-color: #FFFFFF
          display: flex
          flex-direction: column
          min-height: 80px
          border-radius: 8px
          border: 1px solid $orange
          padding: 0
          align-items: center

          &__content
            display: flex
            flex-direction: row
            padding: 20px
            height: 80px
            width: 100%

            &__details
              flex: 1
              display: flex
              flex-direction: row

              .flex-item
                display: flex
                align-items: center

              .amount
                .offer-amount
                  color: $dark
                  font-weight: normal
                  letter-spacing: 0px
                  text-transform: uppercase
                  margin-bottom: 18px

                  &__price
                    display: flex

                    span:nth-child(1)
                      font-size: 24px
                      line-height: 24px

                    span:nth-child(2)
                      font-size: 13px
                      line-height: 13px

              .reduction
                .offer-reduction
                  color: $dark
                  font-weight: normal
                  letter-spacing: 0px
                  text-transform: uppercase

                  &__price
                    display: flex

                    span:nth-child(1)
                      font-size: 24px
                      line-height: 24px

                    span:nth-child(2)
                      font-size: 13px
                      line-height: 13px

                  &__cashback
                    letter-spacing: 0.5px
                    font-size: 10px
                    margin-top: 5px

              .separator
                position: relative
                display: flex
                align-items: center
                justify-content: center
                margin: 0 10px

                .lh
                  color: $orange
                  margin-bottom: 18px
                  font-size: 14px

.step5
  &__right
    &__slimpay
      height: 425px

      iframe
        border: none

    &__skip
      background: white
      border-radius: 4px
      color: $orange
      font-size: 14px
      font-weight: normal
      height: 50px
      letter-spacing: 2px
      padding: 0
      text-align: center
      text-transform: uppercase
      width: 100%
      border: 1px solid $orange
